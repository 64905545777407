import Vue from 'vue'
import VueRouter from 'vue-router'
import Main from '../views/Main.vue'
import Result from '../views/Result'
import EntryForm from '../views/EntryForm.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Main',
    component: Main
  },
  {
    path: '/token',
    name: 'Result',
    component: Result
  },
  {
    path: '/entry-form',
    name: 'entry-form',
    component: EntryForm
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
