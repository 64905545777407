<template>
  <v-container fluid class="pa-0 ma-0" v-if="data && form">
    <v-col class="pa-0 d-flex justify-center flex-column text-center pt-10">
      <h1>A SUHAI tem a cobertura perfeita para você</h1>
      <h2>Confira abaixo as opções:</h2>
    </v-col>
    <v-col class="pa-0 d-flex flex-column pt-5 justify-center mx-auto">
      <v-col lg="8" cols="10" class="pa-0 d-flex justify-center pt-5 mx-auto"
      v-for="(cb) in coberturas"
      :key="cb.$.nome"
      >
        <v-btn
        color="var(--purple)"
        max-width="800"
        width="100%"
        height="100%"
        :outlined="selectedCb && selectedCb.$.nome === cb.$.nome"
        @click="setCb(cb)"
        >
          <span :class="selectedCb && selectedCb.$.nome === cb.$.nome ? 'span-btn-disable pa-2' : 'span-btn pa-2'">{{cb.$.nome}}</span>
        </v-btn>
      </v-col>
    </v-col>
    <v-col lg="10" cols="12" class="pa-0 pt-10 mx-auto pb-10" v-if="selectedCb">
      <v-row no-gutters>
        <v-col lg="8" cols="10" class="pa-0 pr-lg-15 pr-lg-0 mx-auto">
          <table style="width: 100%">
            <thead>Cobertura - {{selectedCb.$.nome}}</thead>
            <tr>
              <td>Assistência 24h</td>
              <td style="text-align: end" v-if="check24Support()"><v-icon color="green">mdi-check</v-icon> Contratado</td>
              <td style="text-align: end" v-else><v-icon color="red">mdi-close-circle</v-icon></td>
            </tr>
            <tr>
              <td>RCF - Danos Materiais</td>
              <td style="text-align: end" v-if="checkRCF()">{{data.Calculo.RCF.valorISDanosMateriais | currency}}</td>
              <td style="text-align: end" v-else><v-icon color="red">mdi-close-circle</v-icon> Não contratado</td>
            </tr>
            <tr>
              <td>RCF - Danos Corporais</td>
              <td style="text-align: end" v-if="checkRCF()">{{data.Calculo.RCF.valorISDanosCorporais | currency}}</td>
              <td style="text-align: end" v-else><v-icon color="red">mdi-close-circle</v-icon> Não contratado</td>
            </tr>
            <tr>
              <td>RCF - Danos Morais</td>
              <td style="text-align: end" v-if="checkRCF()">{{data.Calculo.RCF.valorISDanosMorais | currency}}</td>
              <td style="text-align: end" v-else><v-icon color="red">mdi-close-circle</v-icon> Não contratado</td>
            </tr>
            <tr>
              <td>Prêmio Líquido</td>
              <td style="text-align: end">{{selectedCb.$.premioLiquido | currency}}</td>
            </tr>
            <tr v-if="selectedCb.Parcelamento[0].$.valorTotal">
              <td>Prêmio Total</td>
              <td style="text-align: end">{{selectedCb.Parcelamento[0].$.valorTotal | currency}}</td>
            </tr>
          </table>
          <v-col class="pa-0 d-flex justify-center">
            <v-btn
            :outlined="false"
            color="var(--purple)"
            style="text-transform: none"
            class="mt-10"
            width="300"
            height="50"
            rounded
            id="ClickGostei"
            :disabled="handlingh"
            @click="hire"
            >
              <span 
              v-if="!handlingh"
              style="fontSize: 1.2em" 
              class='span-btn'>Gostei dessa cobertura!</span>
              <v-progress-circular
                v-else
                indeterminate
                size="25"
                color="#fff"
              ></v-progress-circular>
            </v-btn>
          </v-col>
        </v-col>
        <v-col cols="10" class="pa-0 pt-10 pb-10 mx-auto">
          <v-expansion-panels v-model="panel">
            <v-expansion-panel>
              <v-expansion-panel-header>Visualizar opções pagamento</v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-col class="pa-0 d-flex flex-column justify-center mx-auto">
                  <span style="color: var(--black); font-weight: 700" class="text-center pt-2 pb-2" v-for="item in selectedCb.Parcelamento" :key="item.quantidade">
                    {{item['$'].quantidade}}x de {{item['$'].valorTotal | currency}} {{item['$'].valorJuros > 0 ? '- com juros' : '- sem juros'}}
                  </span>
                </v-col>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>
    </v-col>
    <v-dialog 
    v-model="dialog"
    max-width="800"
    >
      <v-card
      rounded
      width="100%"
      height="100%"
      style="position: relative"
      class="pa-lg-15 pa-md-10 pa-5 elevation-1 text-center"
      >
        <v-col class="pa-0">
          <v-col class="pa-0 pb-2">
            <v-icon size="100" color="var(--green)">mdi-check-decagram</v-icon>
          </v-col>
          <p>Agora falta pouco para você estar segurado(a)! 
          Siga as instruções enviadas no seu e-mail. Nossa equipe entrará em contato em breve caso precise de auxílio.
          Se preferir, você também pode nos chamar via WhatsApp.</p>
          <v-btn
          color="green"
          rounded
          @click="openWhatsApp"
          id="WppButton"
          >
          <v-row no-gutters class="d-flex align-center">
            <v-icon color="#fff">mdi-whatsapp</v-icon>
            <span style="font-family: Mulish; color: #fff">(11) 91640-7394</span>
          </v-row>
          </v-btn>
        </v-col>
        <v-col cols="12" md="6" lg="6" xl="6" class="pa-0 pt-10 mx-auto">
          <v-btn
          :outlined="false"
          color="var(--green)"
          style="text-transform: none"
          width="100%"
          rounded
          @click="redirect('https://helpsafeseguros.com.br/')"
          >
            <span 
            style="fontSize: 0.8em" 
            class='span-btn'>Voltar para Home</span>
          </v-btn>
        </v-col>
        <v-col cols="12" md="6" lg="6" xl="6" class="pa-0 pt-3 mx-auto">
          <v-btn
          :outlined="false"
          color="var(--purple)"
          style="text-transform: none"
          width="100%"
          rounded
          @click="redirect('https://helpsafeseguros.com.br/seguro-moto/')"
          >
            <span 
            style="fontSize: 0.8em" 
            class='span-btn'>Fazer outra cotação</span>
          </v-btn>
        </v-col>
        <v-icon 
        title="Fechar" 
        @click="dialog = false" 
        style="position: absolute; right: 10px; top: 10px" size="40" color="red">mdi-close</v-icon>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { webService } from '../services'
import { EventBus } from '../../Eventbus'
import { mdiIcons } from '../constants'

export default {
  data() {
    return {
      data: [],
      dialog: false,
      coberturas: [],
      handlingh: false,
      form: null,
      selectedCb: null,
      id: this.$router.history.current.query.id ? this.$router.history.current.query.id : null,
      headers: [],
      panel: 0
    }
  },
  mounted() {
    webService.get(`/suhai/quotation`, {
      params: {
        id: this.id
      }
    }).then((res) => {
      this.data = res.data.suhai
      this.form = res.data.form
      this.coberturas = res.data.suhai.Calculo.PremioCoberturas
      this.selectedCb = this.coberturas[0]
      this.panel = 0
    }).catch(() => {
      this.$router.push('/')
    })
  },
  methods: {
    openWhatsApp() {
      window.location.href = 'https://api.whatsapp.com/send?phone=5511916407394&text=Ol%C3%A1%2C%20acabei%20de%20fazer%20uma%20simula%C3%A7%C3%A3o%20e%20gostaria%20de%20conversar%20com%20um%20consultor.'
    },
    redirect(route) {
      this.dialog = false
      window.location.href = route
    },
    hire() {
      this.handlingh = true
      const rouboFurto = this.coberturas.find((cobertura) => cobertura.$.id === "1003")
      webService.post('/deal', Object.assign(this.form, {
        id: this.id,
        produto: this.selectedCb.$.nome,
        valor: rouboFurto ? parseFloat(rouboFurto.$.premioLiquido) : 0
      })).then(() => {
        this.dialog = true
        this.handlingh = false
      }).catch(() => {
        this.handlingh = false
        EventBus.$emit('open-dialog', {
          icon: mdiIcons.alert,
          message: "Ocorreu um erro inesperado. Contate o administrador do sistema."
        })
      })
    },
    setCb(cb) {
      this.panel = 1
      this.selectedCb = cb
    },
    checkRCF() {
      switch(this.selectedCb.$.id) {
        case "10031":
          return true
        case "10091":
          return true
        default:
          return false
      }
    },
    check24Support() {
      switch(this.selectedCb.$.id) {
        case "1003":
          return true
        case "1009":
          return true
        case "10091":
          return true
        case "10031":
          return true
        default:
          return false
      }
    },
  }
}
</script>

<style>

h1 {
  font-family: 'Mulish';
  font-size: 2.5em;
}

h2 {
  font-family: 'Mulish';
  font-size: 1.5em;
  font-weight: 100;
}

table {
  border-collapse: collapse;
  font-family: 'Mulish';
  font-size: 16px;
  color: var(--black-secondary);
}

span {
  font-family: 'Mulish';
  font-size: 16px;
  color: var(--black-secondary);
}

table tbody {
  padding: 20px;
}

table th {
  text-align: center;
  font-weight: bold;
  color: #707070;
  border-bottom: 3px solid rgba(0, 0, 0, .1);
}

table td {
  border-bottom: 3px solid rgba(0, 0, 0, .1);
}

table tr {
  height: 3em;
}

table tbody {
  background-color: #fff;
  cursor: pointer;
  margin: 10px;
}

table tbody:hover {
  background-color: #cecece;
}

p {
  font-family: 'Mulish';
  font-size: 1.5em;
  font-weight: 100;
}

.v-btn__content {
  white-space: normal;
  flex: auto;
}
</style>