<template>
  <v-container fluid id="content" class="wrapper pa-0 ma-0">
    <v-col cols="8" class="pa-0 pt-2 margin-20">
      <img width="180" src="../assets/images/selo-corretora.png" alt="">
    </v-col>
    <v-col cols="10" class="pa-0 pl-lg-0 margin-20">
      <h1>Seguro Moto</h1>
      <h2>Proteção à sua motocicleta dia e noite.</h2>
    </v-col>
    <v-col lg="8" cols="12" class="pa-0 ma-15 mx-auto">
      <v-stepper 
      v-model="step"
      class="elevation-0" 
      style="border-radius: 25px" :outlined="false">
        <v-col lg="8" cols="12" class="pa-0 mx-auto">
          <v-stepper-header 
          class="elevation-0"
          style="box-shadow: none!important"
          >
            <v-stepper-step color="var(--purple)" step="1">
            </v-stepper-step>
            
            <v-divider></v-divider>

            <v-stepper-step color="var(--purple)" step="2">
            </v-stepper-step>
            
            <v-divider></v-divider>

            <v-stepper-step color="var(--purple)" step="3">
            </v-stepper-step>
          </v-stepper-header>
        </v-col>
        <v-stepper 
        :outlined="false" 
        v-show="step === 1"
        value="1">
          <v-col lg="8" cols="12" class="pa-0 pa-5 mx-auto">
            <v-form v-model="step1IsValid" @submit.prevent id="FormRCM">
              <v-col class="pa-0 m-auto">
                <label for="cpfCnpj">Qual o número do seu CPF</label>
                <v-text-field
                outlined
                rounded
                id="cpfCnpj"
                color="var(--black)"
                full-width
                v-model="step1Fields.cpfCnpj"
                v-mask="['###.###.###-##']"
                :rules="[rules.validCpf]"
                >

                </v-text-field>
              </v-col>
              <v-col  class="pa-0">
                <label for="dtNascimento">Data de nascimento</label>
                <v-text-field
                color="var(--black)"
                full-width
                id="dtNascimento"
                outlined
                rounded
                :rules="[rules.required, rules.validDate]"
                v-model="step1Fields.dtNascimento"
                v-mask="['##/##/####']"
                >
                </v-text-field>
              </v-col>
              <v-col  class="pa-0">
                <label for="sexo">Informe o seu sexo</label>
                <v-select
                full-width
                id="sexo"
                outlined
                :rules="[rules.required]"
                rounded
                :items="genderOptions"
                color="var(--black)"
                v-model="step1Fields.sexo"
                >

                </v-select>
              </v-col>
              <v-col class="pa-0">
                <label for="estadoCivil">Informe seu estado civil</label>
                <v-select
                full-width
                outlined
                id="estadoCivil"
                :rules="[rules.required]"
                rounded
                :items="maritalStatusOptions"
                color="var(--black)"
                v-model="step1Fields.estadoCivil"
                >

                </v-select>
              </v-col>
              <v-col class="pa-0">
                <label for="utilizacaoDut">Seu veículo é</label>
                <v-select
                full-width
                id="utilizacaoDut"
                outlined
                rounded
                :rules="[rules.required]"
                :items="utilizationDutOptions"
                color="var(--black)"
                v-model="step1Fields.utilizacaoDut"
                >

                </v-select>
              </v-col>
              <v-col class="pa-0">
                <label for="tipoContratacao">Poderia me informar em qual das opções abaixo você se enquadra, por gentileza?</label>
                <v-select
                full-width
                :rules="[rules.required]"
                outlined
                rounded
                id="tipoContratacao"
                v-model="step1Fields.tipoContratacao"
                :items="hiringOptions"
                color="var(--black)"
                >

                </v-select>
              </v-col>
              <v-col class="pa-0" v-if="step1Fields.tipoContratacao === hiringOptions[1].value">
                <label for="dataRetirada">Informe a data de retirada</label>
                <v-text-field
                color="var(--black)"
                full-width
                outlined
                id="dataRetirada"
                :rules="[rules.required, rules.validDate]"
                rounded
                v-model="step1Fields.dataRetirada"
                v-mask="['##/##/####']"
                >

                </v-text-field>
              </v-col>
              <v-col class="pa-0" v-if="step1Fields.tipoContratacao === hiringOptions[2].value">
                <label for="seguradora">Informe a seguradora</label>
                <v-select
                full-width
                outlined
                id="seguradora"
                rounded
                :rules="[rules.required]"
                v-model="step1Fields.seguradora"
                :items="insurers"
                color="var(--black)"
                >

                </v-select>
              </v-col>
              <v-col class="pa-0" v-if="step1Fields.tipoContratacao === hiringOptions[2].value">
                <label for="classeBonus">Informe a classe bônus</label>
                <v-select
                full-width
                outlined
                id="classeBonus"
                rounded
                :rules="[rules.required]"
                v-model="step1Fields.classeBonus"
                :items="['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10']"
                color="var(--black)"
                >

                </v-select>
              </v-col>
              <v-col lg="6" cols="12" class="pa-0">
                <v-btn
                width="100%"
                height="3.5em"
                style="border-radius: 25px"
                color="var(--purple)"
                class="text-capitalize"
                :disabled="!step1IsValid"
                @click="stepSum"
                >
                  <span class="span-btn">Continuar</span>
                </v-btn>
              </v-col>
            </v-form>
          </v-col>
        </v-stepper>
        <v-stepper 
        :outlined="false" 
        v-show="step === 2"
        value="2">
          <v-col lg="8" cols="12" class="pa-0 pa-5 mx-auto">
            <v-form v-model="step2IsValid" @submit.prevent id="FormRCM">
              <v-col class="pa-0 m-auto">
                <label for="placa">Placa</label>
                <v-text-field
                outlined
                rounded
                id="placa"
                color="var(--black)"
                v-mask="['NNNNNNN']"
                full-width
                v-model="step2Fields.placa"
                >

                </v-text-field>
              </v-col>
              <v-row no-gutters>
                <v-col class="pa-0 m-auto pr-2">
                  <label for="informeVeiculo">Informe o seu veículo</label>
                  <v-autocomplete
                  full-width
                  no-data-text="Nenhuma veículo disponível"
                  outlined
                  rounded
                  id="informeVeiculo"
                  :items="vehicles"
                  hide-no-data
                  :rules="[rules.required]"
                  :item-text="item => item.marca + ' ' + item.modelo"
                  item-value="cod_fipe"
                  v-model="step2Fields.vehicle"
                  return-object
                  color="var(--black)"
                  >
                  </v-autocomplete>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col lg="6" cols="12" class="pa-0 m-auto pr-2">
                  <label for="anoFabricacao">Ano de fabricação</label>
                  <v-text-field
                  outlined
                  rounded
                  id="anoFabricacao"
                  color="var(--black)"
                  :rules="[rules.required, rules.validYear]"
                  v-mask="['####']"
                  full-width
                  v-model="step2Fields.anoFabricacao"
                  >

                  </v-text-field>
                </v-col>
                <v-col lg="6" cols="12" class="pa-0 m-auto">
                  <label for="anoModelo">Ano de modelo</label>
                  <v-text-field
                  outlined
                  rounded
                  id="anoModelo"
                  color="var(--black)"
                  :rules="[rules.required, rules.validYear]"
                  v-mask="['####']"
                  full-width
                  v-model="step2Fields.anoModelo"
                  >

                  </v-text-field>
                </v-col>
              </v-row>
              <v-col class="pa-0 m-auto pr-2">
                <label for="zeroKm">Veículo é Zero Km?</label>
                <v-select
                  full-width
                  outlined
                  id="zeroKm"
                  :rules="[rules.required]"
                  rounded
                  :items="zeroKmOptions"
                  color="var(--black)"
                  v-model="step2Fields.zeroKM"
                  >

                </v-select>
              </v-col>
              <v-col class="pa-0 m-auto">
                <label for="cep">Informe o CEP onde o veículo fica guardado à noite</label>
                <v-text-field
                outlined
                rounded
                id="cep"
                v-mask="['#####-###']"
                :rules="[rules.required, rules.validCep]"
                color="var(--black)"
                full-width
                v-model="step2Fields.cepPernoite"
                >

                </v-text-field>
              </v-col>
              <v-row no-gutters>
                <v-col lg="3" cols="12" class="pa-0 pr-2">
                  <v-btn
                  width="100%"
                  height="3.5em"
                  style="border-radius: 25px"
                  color="var(--purple)"
                  class="text-capitalize"
                  @click="stepMinus"
                  >
                    <span class="span-btn">Voltar</span>
                  </v-btn>
                </v-col>
                <v-col lg="3" cols="12" class="pa-0 pt-lg-0 pt-3">
                  <v-btn
                  width="100%"
                  height="3.5em"
                  style="border-radius: 25px"
                  color="var(--purple)"
                  :disabled="!step2IsValid"
                  class="text-capitalize"
                  @click="stepSum"
                  >
                    <span class="span-btn">Continuar</span>
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-col>
        </v-stepper>
        <v-stepper 
        :outlined="false" 
        v-show="step === 3"
        value="3">
          <v-col lg="8" cols="12" class="pa-0 pa-5 mx-auto">
            <v-form v-model="step3IsValid" @submit.prevent id="FormRCM">
              <v-col class="pa-0 m-auto pr-2">
                <label for="tipoUtilizacao">Tipo de utilização</label>
                <v-select
                  full-width
                  outlined
                  id="tipoUtilizacao"
                  :rules="[rules.required]"
                  rounded
                  :items="utilizationTypeOptions"
                  color="var(--black)"
                  v-model="step3Fields.tipoUtilizacao"
                  >

                  </v-select>
              </v-col>
              <v-col class="pa-0 m-auto pr-2">
                <label for="veiculoResidencia">Veículo é guardado em garagem / estacionamento na residência?</label>
                <v-select
                  full-width
                  :rules="[rules.required]"
                  outlined
                  id="veiculoResidencia"
                  rounded
                  :items="residenceOptions"
                  color="var(--black)"
                  v-model="step3Fields.guardadoResidencia"
                  >

                  </v-select>
              </v-col>
              <v-col class="pa-0 m-auto pr-2">
                <label for="veiculoTrabalho">Veículo é guardado em garagem / estacionamento para ir ao trabalho?</label>
                <v-select
                  full-width
                  id="veiculoTrabalho"
                  :rules="[rules.required]"
                  outlined
                  rounded
                  :items="workOptions"
                  color="var(--black)"
                  v-model="step3Fields.guardadoTrabalho"
                  >

                  </v-select>
              </v-col>
              <v-col class="pa-0 m-auto pr-2">
                <label for="veiculoFaculdade">Veículo é guardado em garagem / estacionamento para ir à faculdade/colégio?</label>
                <v-select
                  full-width
                  outlined
                  rounded
                  id="veiculoFaculdade"
                  :rules="[rules.required]"
                  :items="collegeOptions"
                  color="var(--black)"
                  v-model="step3Fields.guardadoFaculdade"
                  >

                  </v-select>
              </v-col>
              <v-col class="pa-0 m-auto pr-2">
                <label for="principalCondutor">O segurado é o principal condutor?</label>
                <v-select
                  full-width
                  id="principalCondutor"
                  outlined
                  :rules="[rules.required]"
                  rounded
                  :items="[ 'Sim', 'Não' ]"
                  color="var(--black)"
                  v-model="step3Fields.principalCondutor"
                  >

                  </v-select>
              </v-col>
              <v-col class="pa-0 m-auto" v-if="step3Fields.principalCondutor == 'Não'">
                <label for="cpfPrincipalCondutor">CPF do Principal Condutor</label>
                <v-text-field
                outlined
                rounded
                id="cpfPrincipalCondutor"
                color="var(--black)"
                :rules="[rules.required, rules.validCpf]"
                full-width
                v-model="step3Fields.cpfCnpjPrincipalCondutor"
                v-mask="['###.###.###-##']"
                >

                </v-text-field>
              </v-col>
              <v-col class="pa-0 m-auto" v-if="step3Fields.principalCondutor == 'Não'">
                <label for="nomePrincipalCondutor">Nome do Principal Condutor</label>
                <v-text-field
                outlined
                rounded
                id="nomePrincipalCondutor"
                color="var(--black)"
                :rules="[rules.required]"
                full-width
                v-model="step3Fields.nomePrincipalCondutor"
                >

                </v-text-field>
              </v-col>
              <v-col  class="pa-0" v-if="step3Fields.principalCondutor == 'Não'">
                <label for="dtNascimentoPrincipalCondutor">Data de nascimento do Principal Condutor</label>
                <v-text-field
                color="var(--black)"
                full-width
                outlined
                id="dtNascimentoPrincipalCondutor"
                :rules="[rules.required, rules.validDate]"
                rounded
                v-model="step3Fields.dtNascimentoPrincipalCondutor"
                v-mask="['##/##/####']"
                >

                </v-text-field>
              </v-col>
              <v-col  class="pa-0" v-if="step3Fields.principalCondutor == 'Não'">
                <label for="sexoPrincipalCondutor">Sexo do Principal Condutor</label>
                <v-select
                full-width
                outlined
                id="sexoPrincipalCondutor"
                :rules="[rules.required]"
                rounded
                :items="genderOptions"
                color="var(--black)"
                v-model="step3Fields.sexoPrincipalCondutor"
                >

                </v-select>
              </v-col>
              <v-col class="pa-0" v-if="step3Fields.principalCondutor == 'Não'">
                <label for="estadoCivilPrincipalCondutor">Estado Civil do Principal Condutor</label>
                <v-select
                full-width
                :rules="[rules.required]"
                outlined
                id="estadoCivilPrincipalCondutor"
                rounded
                :items="maritalStatusOptions"
                color="var(--black)"
                v-model="step3Fields.estadoCivilPrincipalCondutor"
                >

                </v-select>
              </v-col>
              <v-row no-gutters>
                <v-col lg="3" cols="12" class="pa-0 pr-2">
                  <v-btn
                  width="100%"
                  height="3.5em"
                  style="border-radius: 25px"
                  color="var(--purple)"
                  class="text-capitalize"
                  @click="stepMinus"
                  >
                    <span class="span-btn">Voltar</span>
                  </v-btn>
                </v-col>
                <v-col lg="5" cols="12" class="pa-0 pt-lg-0 pt-3">
                  <v-btn
                  width="100%"
                  height="3.5em"
                  style="border-radius: 25px"
                  color="var(--purple)"
                  class="text-capitalize"
                  :disabled="!step3IsValid || handlingh"
                  @click="getCotacao"
                  >
                    <span class="span-btn" v-if="!handlingh">Quero ver minha cotação</span>
                    <v-progress-circular
                    v-else
                    indeterminate
                    size="25"
                    color="#fff"
                    ></v-progress-circular>
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-col>
        </v-stepper>
      </v-stepper>
    </v-col>
    <footer-vue></footer-vue>
  </v-container>
</template>

<script>
import FooterVue from '../components/Footer.vue'
import { webService } from '../services'
import { mdiIcons } from '../constants'
import { EventBus } from '../../Eventbus'

export default {
  components: {
    FooterVue
  },
  data() {
    return {
      handlingh: false,
      step: 1,
      step1IsValid: false,
      step2IsValid: false,
      step3IsValid: false,
      step1Fields: {
        cpfCnpj: null,
        dtNascimento: null,
        sexo: null,
        estadoCivil: null,
        utilizacaoDut: null,
        tipoContratacao: null,
        dataRetirada: null,
        seguradora: null,
        classeBonus: null
      },
      step2Fields: {
        placa: null,
        anoFabricacao: null,
        anoModelo: null,
        zeroKM: null,
        vehicle: null,
        cepPernoite: null
      },
      step3Fields: {
        tipoUtilizacao: null,
        guardadoResidencia: null,
        guardadoTrabalho: null,
        guardadoFaculdade: null,
        principalCondutor: null,
        cpfCnpjPrincipalCondutor: null,
        nomePrincipalCondutor: null,
        dtNascimentoPrincipalCondutor: null,
        sexoPrincipalCondutor: null,
        estadoCivilPrincipalCondutor: null
      },
      genderOptions: [
        { value: 1, text: 'Feminino' },
        { value: 2, text: 'Masculino' }
      ],
      maritalStatusOptions: [
        { value: 1, text: 'Casado/União Estável' },
        { value: 2, text: 'Solteiro' },
        { value: 3, text: 'Separado/Divoricado/Outros' }
      ],
      zeroKmOptions: [
        { value: 'N', text: 'Não' },
        { value: 'S', text: 'Sim' }
      ],
      utilizationDutOptions: [
        { value: 1, text: 'Particular' },
        { value: 2, text: 'Locação (contrato anual)' },
        { value: 3, text: 'Locação (contrato avulso)' },
        { value: 4, text: 'Fretamento' },
        { value: 9, text: 'Auto-escola' },
        { value: 10, text: 'Veículos Serviços Alimentação' },
        { value: 13, text: 'Motos não emplacadas/lazer' },
        { value: 14, text: 'Uso em competições/trilhas/Track Day' },
        { value: 16, text: 'Test Drive' },
        { value: 17, text: 'Moto taxi' },
        { value: 20, text: 'Escolta e segurança' },
        { value: 21, text: 'Moto-Fretistas/Moto-Entregadores' },
        { value: 23, text: 'Motos elétricas - não emplacadas' },
      ],
      utilizationTypeOptions: [
        { value: 1, text: 'Ida-retorno ao trabalho/lazer' },
        { value: 2, text: 'Atividade Profissional' },
        { value: 3, text: 'Lazer exclusivamente' }
      ],
      hiringOptions: [
        { value: 1, text: 'JÁ TEM O VEÍCULO e quer um seguro novo' },
        { value: 2, text: 'COMPROU UM VEÍCULO recentemente, retira em breve e quer retirar segurado' },
        { value: 3, text: 'RENOVANDO O SEGURO de alguma seguradora' },
        { value: 4, text: 'PESQUISANDO UM MODELO PARA COMPRAR e quer receber um link para fazer várias simulações' },
      ],
      residenceOptions: [
        { value: 1, text: 'Sim, garagem na residência' },
        { value: 2, text: 'Sim, garagem em condomínio fechado' },
        { value: 3, text: 'Sim, estacionamento' },
        { value: 4, text: 'Não' },
      ],
      workOptions: [
        { value: 1, text: 'Não utiliza para ir ao trabalho' },
        { value: 2, text: 'Utiliza e guarda no trabalho' },
        { value: 3, text: 'Utiliza mas não guarda no trabalho' },
      ],
      collegeOptions: [
        { value: 1, text: 'Não utiliza para ir à faculdade' },
        { value: 2, text: 'Utiliza e guarda' },
        { value: 3, text: 'Utiliza mas não guarda' },
      ],
      insurers: [
        'SUHAI',
        'Allianz',
        'Azul',
        'Bradesco',
        'HDI',
        'Liberty',
        'Mapfre',
        'Porto Seguro',
        'Sompo',
        'Tokio Marine',
        'Outra'
      ],
      vehicles: [],
      rules: {
        validCep: (value) => {
          const pattern = new RegExp("[0-9]{5}-[0-9]{3}")
          return pattern.test(value) || 'CEP inválido'
        },
        validCpf: (value) => {
          const pattern = /^\d{3}\.\d{3}\.\d{3}\-\d{2}$/
          return pattern.test(value) || 'CPF inválido'
        },
        passwordLowerCaseRequired: password => {
          const pattern = new RegExp("[a-z]")
          return pattern.test(password) || this.errorMessages.lowerCaseRequired;
        },
        required: (value) => {
          return value ? true : 'Campo obrigatório'
        },
        validDate: (value) => {
          const pattern = new RegExp("[0-9]{2}/[0-9]{2}/[0-9]{4}")
          return pattern.test(value) || 'Data inválida'
        },
        validYear: (value) => {
          if(value && value.length >= 4) {
            return true
          } else {
            return "Ano inválido"
          }
        }
      }
    }
  },
  mounted() {
    this.getVehicles()
  },
  methods: {
    stepSum() {
      this.step = this.step + 1
      window.scroll(0, 0)
    },
    stepMinus() {
      this.step = this.step - 1
      window.scroll(0, 0)
    },
    getVehicles() {
      webService.get('/motorcycles').then((res) => {
        this.vehicles = res.data.vehicles
        this.$forceUpdate()
      }).catch((e) => {
        console.log(e)
        EventBus.$emit('open-dialog', {
          icon: mdiIcons.alert,
          message: "Ocorreu um erro inesperado. Contate o administrador do sistema."
        })
      })
    },
    getCotacao() {
      this.handlingh = true
      let payload = Object.assign(this.step1Fields, this.step2Fields, this.step3Fields)
      payload.marca = this.step2Fields.vehicle.marca
      payload.modelo = this.step2Fields.vehicle.modelo

      webService.post('/suhai/quotation', payload).then((res) => {
        const rouboFurto = res.data.suhai.Calculo.PremioCoberturas.find((cobertura) => cobertura.$.id === "1003")
        webService.post('/deal', Object.assign(res.data.form, {
          id: res.data._id,
          valor: rouboFurto ? parseFloat(rouboFurto.$.premioLiquido) : 0
        })).then(() => {
          this.handlingh = false
          this.$router.push(`/token?id=${res.data._id}`)
        }).catch(() => {
          this.handlingh = false
          EventBus.$emit('open-dialog', {
            icon: mdiIcons.alert,
            message: "Ocorreu um erro inesperado. Contate o administrador do sistema."
          })
        })
      }).catch((e) => {
        this.handlingh = false
        if(e.response && e.response.data && e.response.data.message) {
          EventBus.$emit('open-dialog', {
            icon: mdiIcons.alert,
            message: e.response.data.message
          })
        } else {
          EventBus.$emit('open-dialog', {
            icon: mdiIcons.alert,
            message: "Ocorreu um erro inesperado. Contate o administrador do sistema."
          })
        }
      })
    },
  }
}
</script>

<style scoped>
h1 {
  font-family: 'Mulish';
  font-size: 2.5em;
  color: var(--white);
}

h2 {
  font-family: 'Mulish';
  font-size: 1.5em;
  color: var(--white);
  font-weight: 100;
}

label {
  font-family: 'Mulish';
  font-size: 16px;
  color: var(--black-secondary);
  font-weight: bold;
}

.v-input {
  font-family: 'Mulish';
  font-size: 16px;
  color: var(--black-secondary);
}

.margin-20 {
  margin-left: 20%;
}

@media (max-width: 1024px) {
  .wrapper {
    background-size: 50% 50%;
  }

  .margin-20 {
    margin-left: 5%;
  }
}
</style>