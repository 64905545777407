const cookies = Object.freeze({
  vehicles: 'vehicles'
})

const mdiIcons = Object.freeze({
  alert: "mdi-alert-outline",
  check: "mdi-check-decagram"
})

export {
  cookies,
  mdiIcons
}