<template>
  <v-dialog
    v-model="dialog"
    max-width="800"
    @click:outside="destroyDialog"
    >
      <v-card
      rounded
      width="100%"
      height="100%"
      class="pa-lg-15 pa-md-10 pa-5 elevation-1 text-center"
      >
        <v-col class="pa-0 pa-lg-10">
          <h2>{{message}}</h2>
        </v-col>
        <v-col class="pa-0 pt-5" v-if="icon">
          <v-icon size="100" color="var(--green)">{{icon}}</v-icon>
        </v-col>
        <v-col cols="6" class="pa-0 pt-10 mx-auto">
          <v-btn
          :outlined="false"
          color="var(--purple)"
          style="text-transform: none"
          width="100%"
          rounded
          @click="destroyDialog"
          >
            <span 
            style="fontSize: 0.8em" 
            class='span-btn'>Voltar</span>
          </v-btn>
        </v-col>
      </v-card>
    </v-dialog>
</template>

<script>
import { EventBus } from '../../Eventbus'

export default {
  data() {
    return {
      dialog: true
    }
  },
  props: {
    message: String,
    icon: String
  },
  methods: {
    destroyDialog() {
      this.dialog = false
      EventBus.$emit('close-dialog')
      this.$destroy()
    }
  }
}
</script>

<style>

</style>