import axios from 'axios'

const isDev = process.env.NODE_ENV !== 'production'

const webService = axios.create({
  baseURL: isDev ? 'http://localhost:9898' : 'https://suhai-webservice.herokuapp.com'
})

export {
  webService
}