<template>
  <v-app>
    <router-view></router-view>
    <Dialog :message="dialogMessage" :icon="dialogIcon" v-if="dialog" />
  </v-app>
</template>

<script>
import Dialog from './components/Dialog.vue'
import { EventBus } from '../Eventbus'

export default {
  name: 'App',
  components: {
    Dialog
  },
  data: () => ({
    dialog: false,
    dialogIcon: "",
    dialogMessage: ""
  }),
  mounted() {
    EventBus.$on('open-dialog', (dialog) => {
      this.dialog = true
      this.dialogIcon = dialog.icon
      this.dialogMessage = dialog.message
    })

    EventBus.$on('close-dialog', () => {
      this.dialog = false
      this.dialogIcon = ""
      this.dialogMessage = ""
    })
  },
};
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Mulish&display=swap');
html {
  height: auto;
  overflow: auto;
  width: 100%;
  overflow-x: hidden;
  scroll-behavior: smooth;
}

body {
  height: 100%;
  width: 100%;
}

:root {
  --black: #FFFFF;
  --black-secondary: #292929;
  --purple: #9953FF;
  --green: #3094A6;
  --white: #fff;
}

.wrapper {
  background: url('./assets/images/banner-moto.jpg')!important;
  background-size: cover!important;
  background-repeat: no-repeat;
  background-size: 25% 25%;
  height: 450px;
}

.span-btn {
  font-family: 'Mulish';
  font-size: 1em;
  color: #fff;
  font-weight: 600;
}

.span-btn-disable {
  font-family: 'Mulish';
  font-size: 1em;
  color: var(--purple);
  font-weight: 600;
}
</style>
