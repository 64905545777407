<template>
  <v-col class="pa-0 pa-5 mx-auto">
    <v-form v-model="isFormValid" id="FormLpM">
      <v-col class="pa-0">
        <v-text-field
        v-model="name"
        class="elevation-1"
        rounded
        hide-details
        :rules="[rules.required, rules.nameAndLastName]"
        style="font-family: Mulish"
        color="var(--black)"
        label="Nome completo"
        >

        </v-text-field>
      </v-col>
      <v-col class="pa-0 pt-5">
        <v-text-field
        v-model="cpf"
        class="elevation-1"
        rounded
        hide-details
        v-mask="['###.###.###-##']"
        :rules="[rules.required, rules.validCpf]"
        style="font-family: Mulish"
        color="var(--black)"
        label="CPF"
        >
        </v-text-field>
      </v-col>
      <v-col class="pa-0 pt-5">
        <v-text-field
        v-model="email"
        class="elevation-1"
        style="font-family: Mulish"
        rounded
        :rules="[rules.required, rules.email]"
        color="var(--black)"
        hide-details
        label="E-mail"
        >

        </v-text-field>
      </v-col>
      <v-col class="pa-0 pt-5">
        <v-text-field
        v-model="phone"
        hide-details
        class="elevation-1"
        v-mask="'(##) #####-####'"
        :rules="[rules.required, rules.phone]"
        style="font-family: Mulish"
        color="var(--black)"
        rounded
        label="Telefone"
        >

        </v-text-field>
      </v-col>
      <v-col class="pa-0 pt-10 d-flex justify-center">
        <v-btn
        :outlined="false"
        color="var(--purple)"
        style="text-transform: none"
        width="100%"
        rounded
        @click="!handlingh ? send() : null"
        >
          <span 
          v-if="!handlingh"
          style="fontSize: 0.8em" 
          class="span-btn"
          v-html="'Quero ver minha cotação'"></span>
          <v-progress-circular
          v-else
          indeterminate
          size="25"
          color="#fff"
          ></v-progress-circular>
        </v-btn>
      </v-col>
      <v-col class="pa-0 pt-5 text-center" v-if="error">
        <span class="span-btn" style="color: red; fontSize: .8em">
          *Houve um erro com a sua solicitação. Por favor, tente novamente mais tarde.
        </span>
      </v-col>
    </v-form>
  </v-col>
</template>

<script>
import { webService } from '../services'
export default {
  data() {
    return {
      isFormValid: false,
      handlingh: false,
      name: "",
      email: "",
      phone: "",
      error: "",
      cpf: "",
      rules: {
        required: (value) => {
          return value ? true : 'Campo obrigatório'
        },
        email: email => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(email) || "*Necessita ser um email válido.";
        },
        phone: phone => phone.length < 15 ? "*Número de telefone inválido." : !!phone,
        validCpf: (value) => {
          const pattern = /^\d{3}\.\d{3}\.\d{3}\-\d{2}$/
          return pattern.test(value) || '*CPF inválido'
        },
        nameAndLastName: (value) => {
          console.log(value)
          const pattern = /^[a-zA-Z]+ [a-zA-Z]+$/
          console.log(pattern.test(value) )
          return pattern.test(value) || '*Deve ser informado Nome e Sobrenome'
        }
      }
    }
  },
  methods: {
    send() {
      this.handlingh = true
      webService.post('/entry-deal', {
        name: this.name,
        email: this.email,
        phone: this.phone,
        cpf: this.cpf
      }).then(() => {
        this.error = ""
        this.name = ""
        this.email = ""
        this.phone = ""
        this.cpf = ""
        window.top.location.href = 'https://simulacao.helpsafeseguros.com.br/'
      }).catch(() => {
        this.handlingh = false
        this.error = true
      })
    }
  }
}
</script>

<style>

</style>