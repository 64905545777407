<template>
  <footer>
    <v-col class="pa-0 pt-2 pb-10 d-flex flex-column">
      <v-col cols="8" class="pa-0 pt-2 margin-20">
        <img width="180" src="../assets/images/selo-corretora.png" alt="">
      </v-col>
      <v-col cols="8" class="pa-0 pt-2 margin-20">
        <h1>A HelpSafe é uma corretora focada no ramo de seguros automotivos com preços exclusivos com as principais seguradoras.</h1>
      </v-col>
    </v-col>
  </footer>
</template>

<script>
export default {

}
</script>

<style scoped>
footer {
  background-color: var(--green);
}

h1 {
  font-family: 'Mulish';
  font-size: 18px;
  color: var(--white);
  font-weight: 100;
}

.margin-20 {
  margin-left: 20%;
}

@media (max-width: 1024px) {
  .margin-20 {
    margin-left: 5%;
  }
}
</style>